import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from 'src/app/core/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef = undefined;
  private isOpen: boolean = false;
  constructor(private overlay: Overlay) {}

  public show(): void {
    if(!this.isOpen){
      // Hack avoiding `ExpressionChangedAfterItHasBeenCheckedError` error
      Promise.resolve(null).then(() => {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
          hasBackdrop: true,
        });
        this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
        this.isOpen = true
      });
    }
  }

  public hide(): void {
    if(this.isOpen) {
      this.overlayRef.detach();
      this.overlayRef = undefined;
      this.isOpen = false
    }
  }
}
