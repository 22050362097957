<div class="row align-items-center" style="z-index: 1099 !important;">
  <div class="col color-CC3340">
    <!-- <i class="material-icons mr-2 align-bottom">delete</i> -->
    <span class="f-14-700">{{data.message}}</span>
  </div>
  <div class="col-auto">
     <button (click)="snackBarRef.dismiss()" mat-flat-button class="btnColor1-SB ml-2">
      <i class="material-icons fs-14">clear</i>
    </button>
  </div>
</div>
