import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LeadApiService {

  private leadSubject: ReplaySubject<any> = new ReplaySubject()
  leadObserver: Observable<any> = this.leadSubject.asObservable()

  public leadData = null

  baseURL = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private httpClient: HttpClient) {}

  initLeadForm(id = ''): Observable<any> {
    var param = '';
    if (id) {
      param = '/' + id;
    }
    let API_URL = `${this.baseURL + environment.leads + param}`;
    return this.httpClient.get(API_URL);
  }

  addLead(data: any): Observable<any> {
    return this.httpClient.post(`${this.baseURL + environment.leads}`, data);
  }

  updateLead(id, data: any): Observable<any> {
    return this.httpClient.put(`${this.baseURL + environment.leads}/${id}`, data);
  }

  updateLeadStatus(id, data: any): Observable<any> {
    return this.httpClient.put(`${this.baseURL + environment.lead_status}/${id}`, data);
  }

  deleteActivity(menu, data) {
    const API_URL = `${this.baseURL}activity/${menu}?_method=DELETE`
    return this.httpClient.post(API_URL, data)
  }

  deleteLead(LeadIds: any[]): Observable<any> {
    if (LeadIds.length > 1) {
      const data = { ids: LeadIds }
      const API_URL = `${this.baseURL + environment.lead_delete_multiple}`
      return this.httpClient.post(API_URL, data)
    }
    else {
      const leadId = LeadIds[0]
      const API_URL = `${this.baseURL + environment.lead_delete}/${leadId}`
      return this.httpClient.delete(API_URL)
    }
  }

  listLeadGridView(data): Observable<any> {
    return this.httpClient.post(
      `${this.baseURL + environment.leads + "/grid"}`,
      data
    );
  }

  getLeadList(data): Observable<any> {
    return this.httpClient.post(
      `${this.baseURL + environment.leads + "/index"}`,
      data
    );
  }

  changeLeadStage(lead_id, stage_id){
    return this.httpClient.put(
      `${this.baseURL + environment.leads + "/stage/"+ lead_id}`,
      {stage_id: stage_id}
    );
  }
  getLeadDetial(leadId): Observable<any> {
    return this.httpClient.get(`${this.baseURL + environment.lead_detail}/${leadId}`)
  }

  createAppointment(appoint): Observable<any> {
    const API_URL = `${this.baseURL + environment.lead_create_appointment}`
    return this.httpClient.post(API_URL, appoint)
  }

  updateAppointment(id, appoint): Observable<any> {
    const API_URL = `${this.baseURL + environment.lead_update_appointment}/${id}`
    return this.httpClient.put(API_URL, appoint)
  }

  updateAppointmentState(id: number, data) {
    const API_URL = `${this.baseURL + environment.lead_appointment_state}/${id}`
    return this.httpClient.put(API_URL, data)
  }


  getPipelines(): Observable<any>{
    return this.httpClient.get(`${this.baseURL + environment.pipelineMaster}`);
  }
  getFilterValues(): Observable<any>{
    return this.httpClient.get(`${this.baseURL + environment.leads + "/filter"}`);
  }

  notifyLead() {
    this.leadSubject.next()
  }

  /* Helper Functions */
  getPipelines_() {
    if (this.leadData) {
      return this.leadData.pipelines
    }
    return null
  }
  getCurrency_(){
    if (this.leadData) {
      return this.leadData.currency
    }
    return null
  }
  getOrganizations_(){
    if (this.leadData) {
      return this.leadData.organizations
    }
    return null
  }
  getSources_(){
    if (this.leadData) {
      return this.leadData.sources
    }
    return null
  }
  getContacts_(){
    if (this.leadData) {
      return this.leadData.contacts
    }
    return null
  }
  getOwners_(){
    if (this.leadData) {
      return this.leadData.owner
    }
    return null
  }
  getLeadData_(){
    if (this.leadData) {
      return this.leadData.lead
    }
    return null
  }
}
