<header class="contactHeader">
    <div class="container-fluid px-5">
        <div class="row align-items-center">
            <div class="col-auto menuItems">
                <span class="userImage pb-3" [matMenuTriggerFor]="usersettings">
          <span><mat-icon aria-hidden="false">more_vert</mat-icon></span>
                <img alt="Image" [src]=userImage>
                <!-- <img alt="Image" src="../../../assets/images/user-sample.png"> -->
                <mat-menu #usersettings="matMenu" class="settingsPanel">
                    <ul class="settingsPanelUL">
                        <li class="mb-2" mat-menu-item (click)="goToPage('/pages/settings')">
                            <div  class="f-16-500 color-253238 mb-1">{{profile.full_name}}</div>
                            <a class="f-12-400 color-lite-O">View Profile</a>
                        </li>
                        <!-- <li mat-menu-item>
                            <a class="f-14-400 color-253238"><img class="mr-2" src="../../../assets/images/startedIcon.png" alt="Image">Getting Started</a>
                        </li> -->
                        <li mat-menu-item (click)="goToPage('/pages/settings')">
                            <a routerLink='/pages/settings' class="f-14-400 color-253238"><img class="mr-2" src="../../../assets/images/settingsIcon.png" alt="Image">Settings</a>
                        </li>
                        <!-- <li mat-menu-item>
                            <a class="f-14-400 color-253238"><img class="mr-2" src="../../../assets/images/getHelpIcon.png" alt="Image">Get Help</a>
                        </li> -->
                        <li mat-menu-item (click)="goToPage('/user/login')">
                            <a routerLink='/user/login' class="f-14-400 color-253238" (click)="logout()"><img class="mr-2" src="../../../assets/images/logoutIcon.png" alt="Image">Logout</a>
                        </li>
                    </ul>
                </mat-menu>
                </span>
                <span class="activeMenu">
          <ng-container *ngFor="let menu of menus;">
            <a routerLink="{{menu.link}}" class="ml-3 p-3"
            routerLinkActive="activeMenuItem"
          [routerLinkActiveOptions]="{exact: true}">
              <img src="../../../assets/images/{{menu.icon}}" alt="Image">
            </a>
          </ng-container>
        </span>
          <!-- <span class="ml-3 p-3" [matMenuTriggerFor]="notification">
            <img src="../../../assets/images/menu007.png" alt="Image" *ngIf="userNotifications.length>0">
            <img width="25" src="../../../assets/images/notification004.png" alt="Image" *ngIf="userNotifications.length==0">
          <mat-menu #notification="matMenu" class="notificationPanel">
            <div class="notificationPanelWrap">
              <ul>
               <li mat-menu-item>
                 <span class="color-253238 f-16-700 mr-2">Notifications </span>
                 <mat-icon aria-hidden="false">chevron_right</mat-icon>
                </li>
                <li mat-menu-item *ngFor="let notification of userNotifications" >
                    <div class="row alignText">
                        <div class="col-auto pr-0">
                            <img src="../../../assets/images/notification001.png" alt="Image">
                        </div>
                        <div class="col">
                            <div class="f-12-400 color-253238">{{notification.message}} <a class="color-253238 textUnderline" (click)="markNotificationViewed(notification.id)">click here</a> to mark it viewed.</div>
                            <div class="f-10-500 color-253238 mt-1">{{notification.created_at | date:'short'}}</div>
                        </div>
                    </div>
                </li>
                <li class="showMore text-center" mat-menu-item *ngIf="showLimit == 5">
                    <span class="f-12-500 color-002868" (click)="showMore()">Show More</span>
                </li>
                </ul>
            </div>
            </mat-menu>
            </span> -->
        </div>

        <div class="col-auto ml-auto forSM">
            <span class="pb-3" [matMenuTriggerFor]="addButton">
          <button class="btnColor1-R f-16-500" mat-flat-button color="primary" >
            <span class="d-none-sm">Add</span>
            <mat-icon aria-hidden="false">add</mat-icon>
            </button>
            <mat-menu #addButton="matMenu" class="settingsPanel">
                <ul class="addPanelUL">
                    <li mat-menu-item (click)="clickLead()">
                        <a class="f-16-500 color-253238"><img class="mr-3" src="../../../assets/images/leadIcon.png" alt="Image">Lead</a>
                    </li>
                    <li mat-menu-item (click)="clickContact()">
                        <a class="f-16-500 color-253238"><img class="mr-3" src="../../../assets/images/personIcon.png" alt="Image">Contact</a>
                    </li>
                    <li mat-menu-item (click)="clickCompany()">
                        <a class="f-16-500 color-253238"><img class="mr-3" src="../../../assets/images/companyIcon.png" alt="Image">Company</a>
                    </li>
                </ul>
            </mat-menu>
            </span>
        </div>

        <div class="col-auto d-none-sm">
            <!-- <div class="input-group topSearch">
            <input type="text" class="form-control" placeholder="Search">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon1">
                <mat-icon aria-hidden="false">search</mat-icon>
              </span>
                </div>
            </div>
            </div> -->
            <mat-form-field class="searchForm noLabelForm headerSearch" appearance="outline">
                <input type="text" aria-label="Number" [(ngModel)]="searchText" matInput (keyup.enter)="searchFilter()" placeholder="Search">
                <mat-icon matSuffix (click)="searchFilter()">search</mat-icon>
            </mat-form-field>
        </div>

    </div>
    </div>
</header>
